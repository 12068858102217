@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&family=Mulish:wght@800;900&family=Roboto&display=swap');

:root {
  --primary: #233653;
  --secondary: #eaf0fe;
  --border-color-base: #d9d9d9;
  --border-radius-base: 7px;
  --danger-color: #ff4d4f;
}

h1 {
  font-size: 32px;
  font-weight: 600;
}

h2 {
  font-size: 28px;
  font-weight: 600;
}

h3 {
  font-size: 22px;
  font-weight: 600;
}

h4 {
  font-size: 18px;
  font-weight: 600;
}

h5 {
  font-size: 16px;
  font-weight: 600;
}

a:hover {
  color: currentColor;
  opacity: 0.8;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  max-width: 1140px;
}

.view {
  padding: 24px 0;
}

.fields-column-2 {
  display: grid;
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .fields-column-2 {
    gap: 24px;
    grid-template-columns: 1fr 1fr;
  }
}
